import {Context, FC, useContext, createContext, useState, useEffect} from "react";
import {Props} from "../util/types";
import {OffenseType, Report, ReportAction} from "../util/internalTypes";
import {menuItemClasses} from "@mui/material";

interface SessionData {
    battleTag: string,
    discordId: string
}

interface ErrorData {
    error: string
}

interface ApiQueryGenerator {
    getSession: () => Promise<SessionData | ErrorData>
    getReports: (page?: number, perPage?: number, gmId?: number) => Promise<{data: Report[], pagination: {totalPages: number, totalRecords: number}}>
    getOffenseTypes: () => Promise<OffenseType[]>
    getReportActions: (gmId?: number) => Promise<ReportAction[]>
    putReportAction: (data: ReportAction) => Promise<ReportAction>,
    deleteReportAction: (id: number, gmId?: number) => Promise<any>
    closeReport: (id: number) => Promise<any>
    reopenReport: (id: number) => Promise<any>
}

interface ApiContextData {
    request: (path: string, options: RequestInit) => Promise<any>
    login: (gateway?: string) => void
    query: ApiQueryGenerator,
    session: {
        battleTag: string
        gateway: string
    } | null
}

const getApiHost = () => {
    let prefix = 'api'
    switch (window.location.hostname) {
        case 'mod-sc.team-oze.org':
            prefix = 'api-sc';
            break;
        case 'mod-sc-oz.team-oze.org':
            prefix = 'api-sc-oz';
            break;
        default:
            prefix = 'api';
    }
    return `${prefix}.team-oze.org`
}

const API_HOST = getApiHost()
const getApiUrl = (path = '/') => `${window.location.protocol}//${API_HOST}${path.startsWith('/') ? '' : '/'}${path}`

const request = async (path: string, options: RequestInit = {}) => {
    options.credentials = 'include'
    const res = await fetch(getApiUrl(path), options)
    if (res.status != 200) {
        return null
    }
    else return res
}
const requestJson = async (path: string, options: RequestInit = {}) => {
    options.credentials = 'include'
    const res = await request(path, options)
    if(!res) return null
    else {
        try {
            return await res.json()
        }
        catch (e) {
            console.error(e)
            return null
        }
    }
}

const forceLogin = (gateway = 'eu') => {
    window.location.href = getApiUrl(`/integrations/bnet/oauth/${gateway}?goto=${encodeURIComponent(window.location.href)}`)
}

const query = {
    getSession: async () => {
        return await requestJson('/session')
    },
    getReports: async (page: number = 1, perPage: number = 50, gmId = 1) => {
        return await requestJson(`/reports?${(new URLSearchParams({page: page.toString(), perPage: perPage.toString(), gamemodeId: gmId.toString()})).toString()}`)
    },
    getOffenseTypes: async () => {
        return await requestJson('/offenses')
    },
    getReportActions: async (gmId: number = 1) => {
        return await requestJson(`/report-actions?gamemodeId=${gmId}`)
    },
    putReportAction: async (data: ReportAction) => {
        return await requestJson(`/reports/${data.reportId}/action`, {
            body: JSON.stringify(data),
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    deleteReportAction: async (id: number, gmId: number = 1) => {
        return await requestJson(`/report-actions/${id}?gamemodeId=${gmId}`, {method: 'DELETE'})
    },
    closeReport: async (id: number) => {
        return await requestJson(`/reports/${id}/close`, {method: 'PATCH'})
    },
    reopenReport: async (id: number) => {
        return await requestJson(`/reports/${id}/reopen`, {method: 'PATCH'})
    }
}

const ApiContext = createContext<ApiContextData>({
    request: requestJson,
    login: () => {},
    query,
    session: null
})
export const useApi = () => useContext(ApiContext)

export const ApiProvider: FC<Props> = ({children, ...props}) => {

    const [session, setSession] = useState<{battleTag: string, gateway: string} | null>(null)

    const login = () => {
        if(!session || !session.gateway) {
            let gtw = null
            while (!gtw || !['eu', 'us', 'kr', 'tw', 'cn'].includes(gtw)) gtw = prompt('Choose your gateway (input any of `eu, us, kr, tw, cn`')
            forceLogin(gtw)
        }
        else {
            forceLogin(session.gateway)
        }
    }

    useEffect(()=>{
        query.getSession().then((d)=>!!d?setSession(d):login())
    }, [])


    return <ApiContext.Provider value={{request: requestJson, login, query, session}}>
        {children}
    </ApiContext.Provider>
}
